import React, { ReactNode } from "react";
import { formatDollar } from "../../services/app/formats";
import { getNonTaxLineItems, isRemoteCheckout, isValidTotal } from "../../services/app/invoice";
import { PaymentMethods } from "../../constants/invoice";
import { CheckTypes } from "../../constants/deposit";
import { Company, Invoice, Deposit, Fee, PriceBreakdown, GlobalState } from "../../types";
import {
    isAdvanceToCheckoutDirectPaymentsEnabled,
    isAllowDisableConvFee,
    isCardOnFileEnabled,
    isFeatureEnabled,
    isPreValidatePaymentEnabled,
    isSendTotalToPayerButtonEnabled,
} from "../../services/app/company";
import { LineItem } from "../../types/LineItems";
import { P, Box, Divider, Grid, List, ListItem, ListItemSecondaryAction, ListItemText, ListSubheader, FullScreenLoader } from "@roadsync/roadsync-ui";
import { Hidden, ListItemIcon, StyledComponentProps, Switch, withStyles } from "@material-ui/core";
import { LargeButton, LargePrimaryButton } from "../ui/Buttons";
import InvoiceFinalizeButtonText from "../invoice/InvoiceFinalizeButtonText";
import Alert from "../ui/Alert";
import InvoiceTypeLabel from "../invoice/InvoiceTypeLabel";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardRoundedIcon from "@material-ui/icons/ArrowDownwardRounded";
import { connect, DispatchProp } from "react-redux";
import { FormErrors, getFormSyncErrors, submit } from "redux-form";
import ProductListLineItem from "../invoice/ProductListLineItem";
import ScrollToBottomButton from "../ui/ScrollToBottomButton";
import styles from "./ProductListTotal.css";
import { CardDetailsFormData } from "../../containers/invoice/preparation/steps/CardDetailsForm";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { InvoicePaths } from "../../services/app/paths";
import { openModal } from "../../actions/modals";
import { ModalsConstants } from "../../constants/modals";
import { deleteInvoice } from "../../actions/invoices";
import { preEditFormName } from "../invoice/PreEditForm";
import { comdataFuelCardFormName } from "../invoice/ComdataFuelCardForm";
import { isBoolean, isEqual } from "lodash";
import {
    isCardEnabled,
    isCashEnabled,
    isCreditCardAtPublicCheckoutOnlyEnabled,
    isDirectBillEnabled,
    isHostBillEnabled,
    isPublicCheckoutEnabled,
    isWexEnabled,
    isAchPaymentEnabled,
} from "../../services/app/company";
import CustomStyledAlert from "../invoice/CustomStyledAlert";
import RemoteCheckoutGrandTotals from "./RemoteCheckoutGrandTotals";
import { showErrorAlert } from "../../actions/alerts";
import { isClientSupport, isEmployee, isRSEmployee, isSuperAdmin } from "../../services/app/auth";
import { ProductType } from "../../constants/product";
import DeviceSelectorModal from "../cardReaders/DeviceSelectorModal";
import { payButtonPressed } from "../../services/api/invoices";
import { FeatureTypes } from "../../constants/feature";
export const directPaymentMessage = "This invoice has a direct payment from Roadsync Advance.";
export const directPaymentFeatureOffMessage = "Direct payment is off for this company. Please change payment method.";

type PropsFromState = Pick<GlobalState, "auth"> & {
    formErrors?: FormErrors<{}, string>;
    cardDetailsFormValues?: CardDetailsFormData;
};

interface RouteParams {
    invoiceId?: string;
}

type Props = StyledComponentProps & DispatchProp & OwnProps & PropsFromState & RouteComponentProps<RouteParams>;

interface OwnProps {
    selectedProducts: LineItem[];
    invoice: Invoice;
    company?: Company;
    isPreview?: boolean;
    deposit?: Deposit;
    finalizeInvoice?: boolean;
    formName?: string;
    fees?: Fee[];
    price?: PriceBreakdown;
    checkoutBtnLabel?: React.ReactNode;
    onDone: (selectedProducts: LineItem[], exitNow?: boolean, sendDetails?: boolean) => void | Promise<unknown>;
    handleSubmit?: (event: MouseEvent, values?: CardDetailsFormData) => void;
    handleLineItemsSubmit?: (values?: boolean) => void;
    updateConvFee: (convFeeEnabled?: boolean) => void;
    changePaymentMethod: () => void;
    handlePrintReceipt?: () => void;
    infoBlock?: ReactNode;
    disableChargeBtnComdataV2?: boolean;
    finalForm?: boolean;
    isCardOnFileClonedInvoice?: boolean;
    handleCardOnFileSubmit?: () => void;
    disableSubmitButton?: boolean;
    hideSubmitButton?: boolean;
    showUseCardReader?: boolean;
    setShouldEnableAchForInvoice?: (v?: boolean) => Promise<void>;
    shouldEnableAchForInvoice?: boolean;
    isFinalizeScreen?: boolean;
}

interface State {
    submitting?: boolean;
    storePaymentDetails?: boolean;
    isCashEnabled?: boolean;
    isCardEnabled?: boolean;
    isRemoteCheckoutEnabled?: boolean;
    isHostBillEnabled?: boolean;
    isDirectBillEnabled?: boolean;
    isWexEnabled?: boolean;
    isAchPaymentEnabled?: boolean;
    price?: PriceBreakdown;
    isComdataCardOn?: boolean;
    isAdvanceToCheckoutDirectPaymentsOn?: boolean;
    isTowbookInvoice?: boolean;
    isSelectDeviceOpen: boolean;
    isPreValidatePayment?: boolean;
}

class ProductListTotal extends React.Component<Props, State> {
    private mounted = false;

    constructor(props: Props) {
        super(props);
        this.saveForLater = this.saveForLater.bind(this);
        this.sendTotalToPayer = this.sendTotalToPayer.bind(this);
        this.proceed = this.proceed.bind(this);
        this.updateConvFee = this.updateConvFee.bind(this);
        this.goBackToInvoiceList = this.goBackToInvoiceList.bind(this);
        this.confirmDeleteInvoice = this.confirmDeleteInvoice.bind(this);
        this.deleteInvoiceHandler = this.deleteInvoiceHandler.bind(this);
        this.toggleStorePaymentDetails = this.toggleStorePaymentDetails.bind(this);
        this.state = { isSelectDeviceOpen: false };
    }

    async componentDidMount(): Promise<void> {
        this.mounted = true;
        const { invoice, company } = this.props;
        const isComdataCardOn = !isFeatureEnabled(FeatureTypes.COMDATA_FUEL_CARD_KILL_SWITCH, company);
        const isAdvanceToCheckoutDirectPaymentsOn = isAdvanceToCheckoutDirectPaymentsEnabled(company);

        if (this.mounted)
            this.setState({
                isTowbookInvoice: invoice.source === "towbook",
                isCashEnabled: isCashEnabled(company),
                isCardEnabled: isCardEnabled(company) && !isCreditCardAtPublicCheckoutOnlyEnabled(company),
                isRemoteCheckoutEnabled: isPublicCheckoutEnabled(company),
                isHostBillEnabled: isHostBillEnabled(company),
                isDirectBillEnabled: isDirectBillEnabled(company),
                isWexEnabled: isWexEnabled(company),
                isAchPaymentEnabled: isAchPaymentEnabled(company),
                isComdataCardOn,
                storePaymentDetails: invoice?.storePaymentDetails,
                isAdvanceToCheckoutDirectPaymentsOn,
                isPreValidatePayment: isPreValidatePaymentEnabled(company),
            });
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    isFormInvalid(): boolean {
        const { formErrors } = this.props;
        return formErrors ? !!Object.keys(formErrors)?.length : false;
    }

    getTotalLabel(): string {
        const { invoice } = this.props;
        return invoice?.type === PaymentMethods.REMOTE_CHECKOUT.key ? "Subtotal" : "Grand total";
    }

    canProceed(): boolean {
        const { invoice } = this.props;
        return !!invoice?.type && this.isValidTotal() && !this.isFormInvalid();
    }

    isValidTotal(): boolean {
        const { invoice, company } = this.props;
        return isValidTotal(invoice, this.getLineItemsWithoutTaxes(), invoice?.grandTotal, company);
    }

    getSelectedProducts(): LineItem[] {
        const { selectedProducts } = this.props;
        return selectedProducts;
    }

    getLineItemsWithoutTaxes(): LineItem[] {
        return getNonTaxLineItems(this.getSelectedProducts());
    }

    async saveForLater(): Promise<void> {
        const { onDone, handleLineItemsSubmit } = this.props;
        const { storePaymentDetails } = this.state;
        if (handleLineItemsSubmit) {
            handleLineItemsSubmit(storePaymentDetails);
        }
        await onDone(this.getSelectedProducts(), true);
    }

    async sendTotalToPayer(): Promise<void> {
        const { onDone } = this.props;
        await onDone(this.getSelectedProducts(), false, true);
    }

    showConvenienceFeeSwitch(): boolean {
        const { company, isPreview, finalizeInvoice } = this.props;
        return isAllowDisableConvFee(company) && !isPreview && !finalizeInvoice;
    }

    // TODO make it a prop when have time to refactor
    async proceed(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
        event?.preventDefault();
        const {
            finalizeInvoice,
            formName,
            handleSubmit,
            cardDetailsFormValues,
            onDone,
            dispatch,
            finalForm,
            handleLineItemsSubmit,
            handleCardOnFileSubmit,
        } = this.props;
        const { storePaymentDetails } = this.state;

        if (finalizeInvoice && formName === preEditFormName && finalForm && !handleSubmit) {
            document?.getElementById("pre-edit-submit-hidden")?.click();
            return;
        }
        if (finalizeInvoice && formName === comdataFuelCardFormName && finalForm && !handleSubmit) {
            document?.getElementById("comdata-fuel-card-submit-hidden")?.click();
            return;
        }
        if (finalizeInvoice && formName && !handleSubmit) {
            dispatch(submit(formName));
            return;
        }
        if (finalizeInvoice && formName && handleSubmit) {
            handleSubmit(event.nativeEvent, cardDetailsFormValues);
            return;
        }
        if (handleLineItemsSubmit) {
            handleLineItemsSubmit(storePaymentDetails);
        }

        if (handleCardOnFileSubmit) {
            handleCardOnFileSubmit();
            return;
        }

        if (this.mounted) this.setState({ submitting: true });
        try {
            await onDone(this.getSelectedProducts());
        } finally {
            if (this.mounted) this.setState({ submitting: false });
        }
    }

    updateConvFee(e: React.ChangeEvent<HTMLInputElement>, checked?: boolean): void {
        const { updateConvFee } = this.props;
        updateConvFee(checked);
    }

    getProceedBtnLabel(): React.ReactNode {
        const { checkoutBtnLabel, invoice } = this.props;
        return checkoutBtnLabel ? checkoutBtnLabel : <InvoiceFinalizeButtonText invoice={invoice} />;
    }

    showSendTotalToPayerBtn(): boolean {
        const { company } = this.props;
        return isSendTotalToPayerButtonEnabled(company);
    }

    getCardOnFileClonedInvoice(): React.ReactNode {
        const { invoice, classes } = this.props;
        return (
            <>
                {!invoice.type && <Alert error="Please select a payment method" actions={<EditIcon />} data-testid="card-onfile-no-type-alert" />}
                {invoice.type && (
                    <Alert severity={"success"} disabled={true} actions={null} invoice={invoice}>
                        <P variant="h6" id="payment-method-label" data-testid="card-onfile-type-alert">
                            <span className={classes?.invoiceSummaryPaymentMethodLabel}>Payment Method: </span>
                            <InvoiceTypeLabel invoice={{ ...invoice, initialType: invoice.type }} />
                        </P>
                    </Alert>
                )}
            </>
        );
    }

    getPaymentMethodContainer(): React.ReactNode {
        const { changePaymentMethod, invoice, deposit, classes, isCardOnFileClonedInvoice } = this.props;
        let isCheck = invoice.type === PaymentMethods.CHECK.key && invoice.subtype !== CheckTypes.COMDATA_EXPRESS_CODE.key;
        let hideCheckNum = deposit?.checkNumber ? false : true;
        let checkDisplay = deposit?.checkNumber;

        if (invoice.subtype === CheckTypes.COMCHEKV2.key) {
            hideCheckNum = true;
            checkDisplay = "Express Code";
        }

        if (isCardOnFileClonedInvoice) {
            return <>{this.getCardOnFileClonedInvoice()}</>;
        }

        return (
            <>
                {!invoice.type && <Alert onClick={changePaymentMethod} error="Please select a payment method" actions={<EditIcon />} />}
                {invoice.type && (
                    <Alert
                        severity={"success"}
                        onClick={changePaymentMethod}
                        actions={invoice.status !== "validated" ? <EditIcon color="disabled" /> : null}
                        invoice={invoice}
                    >
                        <P variant="h6" id="payment-method-label">
                            <span className={classes?.invoiceSummaryPaymentMethodLabel}>Payment Method: </span>
                            <InvoiceTypeLabel invoice={{ ...invoice, initialType: invoice.type }} />
                            {isCheck && !hideCheckNum && (
                                <>
                                    {" "}
                                    <span id="check-number">#{checkDisplay}</span>
                                </>
                            )}
                            {isCheck && hideCheckNum && (
                                <>
                                    {" "}
                                    <span id="check-number">{checkDisplay}</span>{" "}
                                </>
                            )}
                        </P>
                    </Alert>
                )}
            </>
        );
    }

    getConvenienceFeeCost(): React.ReactNode {
        const { price, invoice } = this.props;
        return invoice?.convFeeDisable ? "Disabled" : formatDollar(price?.ConvenienceFee);
    }

    getStorePaymentDetails(): boolean | undefined {
        const { invoice } = this.props;
        return invoice?.storePaymentDetails;
    }

    showStorePaymentDetails(): boolean {
        const { invoice, company } = this.props;
        return isRemoteCheckout(invoice) && isCardOnFileEnabled(company);
    }

    storePaymentDetailsSection(): React.ReactNode {
        const { handleLineItemsSubmit } = this.props;
        return (
            <ListItem>
                <ListItemText
                    primary={
                        <>
                            <Switch
                                color="primary"
                                checked={this.isToggleSavePaymentInfoChecked()}
                                id="toggle-save-payment-info-btn"
                                onChange={this.toggleStorePaymentDetails}
                                disabled={!handleLineItemsSubmit}
                            />
                            <span id="save-payment-info-copy">Save payment information</span>
                        </>
                    }
                />
            </ListItem>
        );
    }

    getInvoiceLineItems(): React.ReactNode {
        const { invoice } = this.props;
        const { isTowbookInvoice } = this.state;
        const lineItemsWithoutTaxes = this.getLineItemsWithoutTaxes();

        if (isTowbookInvoice) {
            return (
                <>
                    {lineItemsWithoutTaxes
                        .filter((item) => item.type === ProductType.ADD_MULTIPLE_LINE_ITEMS.key)
                        .map((item, i) => (
                            <ProductListLineItem key={i} item={item} invoice={invoice} />
                        ))}
                </>
            );
        }

        return (
            <>
                {!lineItemsWithoutTaxes?.length && (
                    <ListItem>
                        <ListItemText>No products.</ListItemText>
                    </ListItem>
                )}
                {lineItemsWithoutTaxes
                    .filter((item) => item.product)
                    .map((item, i) => (
                        <ProductListLineItem key={i} item={item} invoice={invoice} />
                    ))}
            </>
        );
    }

    toggleStorePaymentDetails(value: React.ChangeEvent<HTMLInputElement>): void {
        if (this.mounted) this.setState({ storePaymentDetails: value?.target?.checked });
    }

    isToggleSavePaymentInfoChecked(): boolean | undefined {
        const { invoice } = this.props;
        const { storePaymentDetails } = this.state;
        return isBoolean(invoice?.storePaymentDetails) && !isBoolean(storePaymentDetails) ? invoice?.storePaymentDetails : storePaymentDetails;
    }

    convenienceFeeSection(): React.ReactNode {
        const { classes, invoice } = this.props;
        return (
            <ListItem className={classes?.convenienceFeeRow}>
                <ListItemText>
                    Convenience Fee
                    {this.showConvenienceFeeSwitch() && (
                        <Switch color="primary" checked={!invoice?.convFeeDisable} id="toggle-convenience-fee-btn" onChange={this.updateConvFee} />
                    )}
                </ListItemText>
                <ListItemSecondaryAction id="convenience-fee">{this.getConvenienceFeeCost()}</ListItemSecondaryAction>
            </ListItem>
        );
    }

    taxSection(): React.ReactNode {
        const { price } = this.props;
        return (
            <ListItem>
                <ListItemText>Tax</ListItemText>
                <ListItemSecondaryAction id="tax-total">{formatDollar(price?.TaxTotal)}</ListItemSecondaryAction>
            </ListItem>
        );
    }

    getComdataApiLocationId(): string | undefined {
        const { invoice } = this.props;
        return typeof invoice?.location !== "string" ? invoice.location?.comdataApiLocationId : undefined;
    }

    getInvoiceSummary(): React.ReactNode {
        const { classes, price, fees, invoice, auth, company, isFinalizeScreen, setShouldEnableAchForInvoice, shouldEnableAchForInvoice } =
            this.props;
        const { isCardEnabled, isComdataCardOn, isWexEnabled, isAchPaymentEnabled } = this.state;

        return (
            <List dense id="invoice-products-section" className={classes?.list}>
                <ListSubheader disableSticky>
                    <ListItemIcon>Qty</ListItemIcon>
                    Description
                    <ListItemSecondaryAction>Price</ListItemSecondaryAction>
                </ListSubheader>
                <Divider component="li" />
                {this.getInvoiceLineItems()}
                <ListItem>
                    <ListItemText>Subtotal</ListItemText>
                    <ListItemSecondaryAction id="sub-total">{formatDollar(price?.Subtotal)}</ListItemSecondaryAction>
                </ListItem>

                {this.convenienceFeeSection()}

                {!!price?.TaxTotal && this.taxSection()}

                <Divider component="li" />
                <ListItem>
                    {/* NOTE: for remote checkout PM the label with id="grand-total-label" will have Subtotal copy */}
                    <ListItemText>
                        <Box id="grand-total-label" className={classes?.grandTotalTitle}>
                            {this.getTotalLabel()}
                        </Box>
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Box id="grand-total" key="grand-total" className={classes?.grandTotal}>
                            {formatDollar(price?.GrandTotal)}
                        </Box>
                    </ListItemSecondaryAction>
                </ListItem>

                <RemoteCheckoutGrandTotals
                    isCardEnabled={isCardEnabled}
                    isComdataCardOn={isComdataCardOn}
                    isWexEnabled={isWexEnabled}
                    comdataApiLocationId={this.getComdataApiLocationId()}
                    isAchPaymentEnabled={isAchPaymentEnabled}
                    fees={fees}
                    invoice={invoice}
                    user={auth?.me}
                    company={company}
                    setShouldEnableAchForInvoice={setShouldEnableAchForInvoice}
                    shouldEnableAchForInvoice={shouldEnableAchForInvoice}
                    isFinalizeScreen={isFinalizeScreen}
                />

                {this.showStorePaymentDetails() && this.storePaymentDetailsSection()}
            </List>
        );
    }

    getSaveForLaterBtn(): ReactNode {
        return (
            <LargeButton variant="outlined" onClick={this.saveForLater} fullWidth id="save-for-later-btn">
                Save <Hidden xsDown>for Later</Hidden>
            </LargeButton>
        );
    }

    goBackToInvoiceList(): void {
        const { history } = this.props;
        history.push(InvoicePaths.listUrl());
    }

    async deleteInvoiceHandler(reason: string): Promise<void> {
        const { dispatch, invoice } = this.props;
        try {
            dispatch<any>(deleteInvoice(invoice, reason));
        } catch (e) {
            dispatch(showErrorAlert(`${(e as any)?.message ?? e}`));
        }
    }

    confirmDeleteInvoice(): void {
        const { dispatch, invoice } = this.props;
        dispatch<any>(
            openModal(ModalsConstants.DELETE_INVOICE_REASON, {
                customText: true,
                deleteHandler: this.deleteInvoiceHandler,
                invoice: invoice,
            })
        );
        this.goBackToInvoiceList();
    }

    isLineItemsScreen(): boolean {
        const { handleLineItemsSubmit, finalizeInvoice } = this.props;
        return !!handleLineItemsSubmit && !finalizeInvoice;
    }

    isInvoiceDirectPaymentType(): boolean {
        const { invoice } = this.props;
        return invoice?.type === PaymentMethods.DIRECT_PAYMENT.key;
    }

    isPaymentDisabled(): boolean {
        const { auth, finalizeInvoice, invoice } = this.props;
        const isUnauthorizedRole = isSuperAdmin(auth?.me) || isRSEmployee(auth?.me) || (isClientSupport(auth?.me) && !isEmployee(auth?.me));
        return finalizeInvoice ? isUnauthorizedRole : invoice?.type === PaymentMethods.CARD.key && isUnauthorizedRole;
    }

    isDirectPaymentCheckoutScreen(): boolean {
        const { finalizeInvoice } = this.props;
        return this.isInvoiceDirectPaymentType() && !!finalizeInvoice;
    }

    showDirectPaymentAlert(): boolean {
        const { isAdvanceToCheckoutDirectPaymentsOn } = this.state;
        const isLineItemsScreen = this.isLineItemsScreen();
        const isDirectPaymentCheckoutScreen = this.isDirectPaymentCheckoutScreen();
        return !!isAdvanceToCheckoutDirectPaymentsOn && this.isInvoiceDirectPaymentType() && (isLineItemsScreen || isDirectPaymentCheckoutScreen);
    }

    showDirectPaymentOffAlert(): boolean {
        const { isAdvanceToCheckoutDirectPaymentsOn } = this.state;
        const isLineItemsScreen = this.isLineItemsScreen();
        const isDirectPaymentCheckoutScreen = this.isDirectPaymentCheckoutScreen();
        return !isAdvanceToCheckoutDirectPaymentsOn && this.isInvoiceDirectPaymentType() && (isLineItemsScreen || isDirectPaymentCheckoutScreen);
    }

    handleUseCardReader = (): void => {
        const { invoice } = this.props;
        payButtonPressed(invoice);
        this.setState({ isSelectDeviceOpen: true });
    };

    handleCloseSelectDeviceModal = (): void => {
        this.setState({ isSelectDeviceOpen: false });
    };

    render(): React.ReactElement {
        const {
            classes,
            handlePrintReceipt,
            formName,
            invoice,
            infoBlock,
            disableChargeBtnComdataV2,
            disableSubmitButton,
            hideSubmitButton,
            showUseCardReader,
        } = this.props;
        const { submitting, isSelectDeviceOpen, isPreValidatePayment } = this.state;

        return (
            <>
                <Box boxShadow={1} p={1} className={classes?.invoiceSummary} id="invoice-summary">
                    <FullScreenLoader show={submitting} />
                    <Grid container direction="column" spacing={1} wrap="nowrap">
                        <Hidden mdUp>
                            <Grid item className={classes?.invoiceSummaryMobileDirectionsSection} id="invoice-mobile-directions">
                                <P variant="h2" align="center" gutterBottom>
                                    Almost Done!
                                </P>
                                <P variant="h4" align="center" gutterBottom>
                                    Please review your invoice below before completing the payment.
                                </P>
                                <P align="center" gutterBottom id="invoice-mobile-directions-icon">
                                    <ArrowDownwardRoundedIcon className={classes?.invoiceSummaryMobileDirectionsSectionArrowDown} />
                                </P>
                                <ScrollToBottomButton containerTarget="#invoice-builder-root" scrollTarget="#invoice-mobile-directions-icon">
                                    Go to Checkout
                                </ScrollToBottomButton>
                            </Grid>
                        </Hidden>
                        <Grid item>{this.getPaymentMethodContainer()}</Grid>
                        {this.showDirectPaymentAlert() && (
                            <CustomStyledAlert className={classes?.directPaymentAlertDesktopOnly}>{directPaymentMessage}</CustomStyledAlert>
                        )}
                        {this.showDirectPaymentOffAlert() && (
                            <CustomStyledAlert className={classes?.directPaymentAlertDesktopOnly} color="error">
                                {directPaymentFeatureOffMessage}
                            </CustomStyledAlert>
                        )}
                        <Grid item>{this.getInvoiceSummary()}</Grid>
                        <Grid item className={classes?.buttonRow}>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <LargeButton variant="outlined" onClick={handlePrintReceipt} fullWidth id="print-btn">
                                        Print <Hidden xsDown>Invoice</Hidden>
                                    </LargeButton>
                                </Grid>
                                <Grid item xs>
                                    {isPreValidatePayment && (
                                        <>
                                            {invoice.type === PaymentMethods.CARD.key &&
                                                invoice.status !== "validated" &&
                                                invoice.status !== "validatedandfailed" && (
                                                    <LargeButton
                                                        onClick={this.proceed}
                                                        type="submit"
                                                        id="validate-save"
                                                        disabled={!this.canProceed()}
                                                        variant={formName === "cardDetails" ? "contained" : "outlined"}
                                                        color={formName === "cardDetails" ? "secondary" : undefined}
                                                        fullWidth
                                                    >
                                                        Validate Card & Save
                                                    </LargeButton>
                                                )}
                                            {invoice.type === PaymentMethods.CARD.key &&
                                                (invoice.status === "validated" || invoice.status === "validatedandfailed") && (
                                                    <LargeButton
                                                        onClick={this.confirmDeleteInvoice}
                                                        type="submit"
                                                        id="void-invoice"
                                                        variant={formName === "cardDetails" ? "contained" : "outlined"}
                                                        color={formName === "cardDetails" ? "secondary" : undefined}
                                                        fullWidth
                                                    >
                                                        Void Invoice
                                                    </LargeButton>
                                                )}
                                            {invoice.type !== PaymentMethods.CARD.key && this.getSaveForLaterBtn()}
                                        </>
                                    )}
                                    {!isPreValidatePayment && this.getSaveForLaterBtn()}
                                </Grid>
                            </Grid>
                        </Grid>
                        {this.showSendTotalToPayerBtn() && (
                            <Grid item>
                                <LargeButton variant="outlined" onClick={this.sendTotalToPayer} id="line-items-send-btn" fullWidth>
                                    Send Details to Payer
                                </LargeButton>
                            </Grid>
                        )}
                        {showUseCardReader && (
                            <Grid item>
                                <LargeButton
                                    id={"use-card-reader-btn"}
                                    data-testid={"use-card-reader-btn"}
                                    onClick={this.handleUseCardReader}
                                    variant="outlined"
                                    fullWidth
                                    disabled={!this.canProceed()}
                                >
                                    Use Card Reader
                                </LargeButton>
                            </Grid>
                        )}
                        {showUseCardReader && (
                            <DeviceSelectorModal invoice={invoice} open={isSelectDeviceOpen} onClose={this.handleCloseSelectDeviceModal} />
                        )}

                        {!hideSubmitButton && (
                            <Grid item>
                                <LargePrimaryButton
                                    id="proceedButton"
                                    data-testid="proceed-button"
                                    fullWidth
                                    disabled={
                                        !this.canProceed() ||
                                        disableChargeBtnComdataV2 ||
                                        this.showDirectPaymentOffAlert() ||
                                        disableSubmitButton ||
                                        this.isPaymentDisabled()
                                    }
                                    onClick={this.proceed}
                                >
                                    {this.getProceedBtnLabel()}
                                </LargePrimaryButton>
                            </Grid>
                        )}
                        {infoBlock}
                    </Grid>
                </Box>
            </>
        );
    }
}

function mapStateToProps(state: GlobalState, props: OwnProps): PropsFromState & { isFinalizeScreen?: boolean } {
    const { auth } = state;
    const { handleLineItemsSubmit, finalizeInvoice } = props;
    const isFinalizeScreen = !handleLineItemsSubmit || finalizeInvoice;
    return {
        cardDetailsFormValues: props?.formName ? state?.form[props?.formName]?.values : undefined,
        formErrors: props?.formName ? getFormSyncErrors(props?.formName)(state) : undefined,
        auth,
        isFinalizeScreen,
    };
}

export default withRouter(withStyles(styles)(connect(mapStateToProps)(ProductListTotal)));
