import React from "react";
import { FormControl, InputLabel, Select, FormHelperText, SelectProps, makeStyles } from "@material-ui/core";
import { FormConfig } from "./FormConfig";
import styles from "./RenderSelectField.css";

interface Props {
    input: Partial<SelectProps>;
    children: React.ReactNode | JSX.Element;
    label: string;
    helperText?: string;
    multiple?: boolean;
    renderValue?: (value: unknown) => React.ReactNode;
    meta: { touched: boolean; error: string };
    backgroundColor?: string;
    margin?: string;
    required?: boolean;
    readOnly?: boolean;
    height42?: boolean;
}

const selectStyles = (backgroundColor?: string, margin?: string): Record<string, any> => {
    return makeStyles({
        input: {
            backgroundColor: `${backgroundColor ? backgroundColor : "unset"}`,
            margin: `${margin ? margin : "unset"}`,
        },
    });
};

// eslint-disable-next-line
export const RenderSelectField: React.FC<Props> = (props: Props): React.ReactElement => {
    const {
        input,
        label,
        children,
        helperText,
        multiple,
        backgroundColor,
        margin,
        meta: { touched, error },
        required,
        readOnly,
        height42,
        ...rest
    } = props;
    const labelId = "label-" + Math.random().toString(36).substr(2, 9);
    const classes = selectStyles(backgroundColor, margin);
    const classes2 = styles();
    if (multiple) {
        input.value = input.value || [];
    }
    const errorMsg = touched && !!error ? error : undefined;
    const helpText = helperText || errorMsg;
    const errorClassName = errorMsg ? "Mui-error" : undefined;

    return (
        <FormControl className={(height42 ? classes2.field42 : "") + " " + classes.input} fullWidth variant={FormConfig.Variant}>
            <InputLabel id={labelId} className={errorClassName}>
                {label}
                {required ? "*" : ""}
            </InputLabel>
            <Select labelId={labelId} label={label} multiple={multiple} readOnly={readOnly} error={touched && !!error} {...rest} {...input}>
                {children}
            </Select>
            {helpText && <FormHelperText className={errorClassName}>{helpText}</FormHelperText>}
        </FormControl>
    );
};

