import SockJS from "sockjs-client";
import { SocketEndpoints } from "../api/endpoints";
import * as SocketService from "../api/sockets";
import {
    cancelledFromHardwareUpdatePush,
    cancelledFromInvoicePriceUpdatedPush,
    receiveInvoiceAmountChangedPush,
    receiveInvoiceUpdatePush,
} from "../../actions/invoices";
import { receiveWorkOrderUpdatePush } from "../../actions/workOrders";
import * as SocketActions from "../../actions/sockets";
import { receiveCheckDepositPush } from "../../actions/checkAuthorizer";
import { reloadApp } from "../api/utils";
import { showSuccessAlert } from "../../actions/alerts";

const INVOICE_PAYLOAD = "invoice";
const WORKORDER_PAYLOAD = "workorder";
const CHECKDEPOSIT_PAYLOAD = "checkdeposit";
const RELOAD_APP = "reloadapp";
const NOTIFICATION = "notification";
const RAINFOREST_PAYLOAD = "rainforest";

const readyState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};

const HANDSHAKE_DELAY = 1000;
const BASE_RETRY_TIMEOUT: number = 5 * 1000;
const RESET_ATTEMPTS_TIMEOUT: number = 30 * 1000;

let isActive = true;
let connectionAttempts = 0;
let dispatchReference: Function;
let socket: any;
let token: string;
let handshakeWasSuccessful = false;
let resetTimeout, verifyHandshakeTimeout, attemptEstablishConnectionTimeout;

/**
 * Connection is established as follows:
 * 1: Request connection token from API
 * 2: Open socket connection
 * 3: Send token via open socket connection
 *
 * If we are unable to connect or connection closes, attempt to reconnect at an increasing delay
 * Dispatch redux actions that correspond to handshake results (fail, open, close)
 *
 * Always clear timeouts before starting new ones
 *
 * @param {Function} dispatch
 * @returns
 */
export const initializeSocketConnection = (dispatch: Function) => {
    dispatchReference = dispatch;
    if (isSocketConnected()) {
        return;
    }

    clearTimeout(attemptEstablishConnectionTimeout);
    if (isActive) {
        const duration = BASE_RETRY_TIMEOUT * Math.pow(connectionAttempts, 2);
        attemptEstablishConnectionTimeout = setTimeout(attemptEstablishConnection, duration);
    }
};

/**
 * Close the active connection (when open) and prevent reconnect attempts
 *
 */
export const abandonSocketConnection = () => {
    isActive = false;
    connectionAttempts = 0;

    if (isSocketConnected()) {
        socket.close();
    }
};

const isSocketConnected = (): boolean => {
    return socket && socket.readyState === readyState.OPEN;
};

const attemptEstablishConnection = (): void => {
    if (!isActive || isSocketConnected()) {
        return;
    }
    connectionAttempts++;
    SocketService.getToken()
        .then((result: any) => {
            token = result.token as string;
            socket = new SockJS(SocketEndpoints.connection());
            socket.onopen = onOpen;
            socket.onmessage = onMessage;
            socket.onclose = onClose;
        })
        .catch((err) => {
            dispatchReference(SocketActions.connectionFailed());
            onClose();
        });
};

const sendMessage = (data) => {
    if (isSocketConnected()) {
        socket.send(JSON.stringify(data));
    }
};

const resetConnectionAttempts = () => {
    if (isSocketConnected()) {
        connectionAttempts = 0;
    }
};

const verifyHandshake = () => {
    if (isSocketConnected()) {
        handshakeWasSuccessful = true;
        dispatchReference(SocketActions.connectionOpened());
    } else {
        dispatchReference(SocketActions.connectionFailed());
    }
};

const onOpen = () => {
    sendMessage({
        type: "auth",
        token,
    });

    clearTimeout(resetTimeout);
    clearTimeout(verifyHandshakeTimeout);

    resetTimeout = setTimeout(resetConnectionAttempts, RESET_ATTEMPTS_TIMEOUT);
    verifyHandshakeTimeout = setTimeout(verifyHandshake, HANDSHAKE_DELAY);
};

// eslint-disable-next-line max-lines-per-function
export const onMessage = (response, dispatchReferenceTesting?: Function) => {
    const message = JSON.parse(response.data);
    if (dispatchReferenceTesting) dispatchReference = dispatchReferenceTesting;

    switch (message.type) {
        case "push":
            if (message.payloadType === INVOICE_PAYLOAD) {
                dispatchReference(receiveInvoiceUpdatePush(JSON.parse(message.payloadData)));
            }

            if (message.payloadType === NOTIFICATION) {
                dispatchReference(showSuccessAlert(message.payloadData));
            }

            if (message.payloadType === WORKORDER_PAYLOAD) {
                dispatchReference(receiveWorkOrderUpdatePush(JSON.parse(message.payloadData)));
            }

            if (message.payloadType === CHECKDEPOSIT_PAYLOAD) {
                dispatchReference(receiveCheckDepositPush(JSON.parse(message.payloadData)));
            }

            if (message.payloadType === RELOAD_APP) {
                dispatchReference(reloadApp(message.payloadData));
            }

            if (message.payloadType === RAINFOREST_PAYLOAD) {
                const action = JSON.parse(message.payloadData).action;

                if (action === "invoice_updated") {
                    dispatchReference(cancelledFromInvoicePriceUpdatedPush(message.payloadData));
                } else if (action === "transaction_cancelled") {
                    dispatchReference(cancelledFromHardwareUpdatePush(message.payloadData));
                }

                if (action === "rainforest_amount_mismatch") {
                    dispatchReference(receiveInvoiceAmountChangedPush(JSON.parse(message.payloadData)));
                }
            }
            break;
        case "error":
            socket.close();
            break;
        case "ping":
            sendMessage({
                type: "pong",
            });
            break;
        case "ok":
            connectionAttempts = 0;
            break;
        default:
            break;
    }
};

const onClose = () => {
    if (handshakeWasSuccessful) {
        handshakeWasSuccessful = false;
        dispatchReference(SocketActions.connectionClosed());
    }

    initializeSocketConnection(dispatchReference);
};

