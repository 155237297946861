import React from "react";

interface Props {
    dataProvider: any[];
    itemRenderer: Function;
    focusIndex: number;
    selectedIndex: number;
    styles: any;
    isVisible: string;
    className: string;
    selectHandler: Function;
    id: string;
}

export default class DropDownList extends React.Component<Props> {
    handleKeyDown: any;
    ul: any;
    activeItem: any;

    // eslint-disable-next-line
    render() {
        const focused = this.props.dataProvider[this.props.focusIndex];
        const selected = this.props.dataProvider[this.props.selectedIndex];
        // eslint-disable-next-line
        const children = this.props.dataProvider.map((item, i) => {
            let className = "list-item";

            if (selected) {
                className += " selected";
            }

            if (focused === item) {
                className += " focused";
            }

            const mouseDownHandler = () => {
                this.props.selectHandler(item);
            };

            const isActive = focused === item ? "active" : "";

            const RenderClass = this.props.itemRenderer as any;

            return (
                <RenderClass
                    key={item.value || i}
                    mouseDownHandler={mouseDownHandler}
                    className={className}
                    role="option"
                    isActive={isActive}
                    ref={(li) => {
                        if (isActive === "active") {
                            this.activeItem = li;
                        }
                    }}
                    itemName={item.name}
                    itemValue={item.value}
                />
            );
        });

        return (
            <div className={`list-values dropdown-style-1 search-result-wrapper ${this.props.isVisible}`} onKeyDown={this.handleKeyDown}>
                <ul
                    ref={(ul) => {
                        this.ul = ul;
                    }}
                    id={`dropdown-${this.props.id}`}
                    role="listbox"
                    className={`${this.props.className || ""}`}
                    aria-labelledby={this.props.id}
                    style={this.props.styles}
                >
                    {children}
                </ul>
            </div>
        );
    }
}

