import React from "react";
import { Alert as MUIAlert } from "@material-ui/lab";
import { ThemeProvider } from "@roadsync/roadsync-ui";
import { Box, ButtonBase, Grid } from "@material-ui/core";
import { Invoice } from "../../types";
import { isEqual } from "lodash";

export interface Props {
    id?: string;
    error?: string | null;
    scrollIntoView?: boolean;
    actions?: React.ReactNode;
    severity?: "success" | "info" | "warning" | "error";
    variant?: "filled" | "outlined" | "standard";
    onClick?: () => void;
    children?: React.ReactNode;
    mt?: number;
    mb?: number;
    icon?: React.ReactNode | false;
    invoice?: Invoice;
    disabled?: boolean;
}

export interface State {
    error?: string | null;
}

class Alert extends React.Component<Props, State> {
    ref: React.RefObject<HTMLElement>;

    constructor(props: Props) {
        super(props);
        this.ref = React.createRef<HTMLElement>();
        this.state = { error: null };
    }

    componentDidMount(): void {
        this.onChange();
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    componentDidUpdate(): void {
        this.onChange();
    }

    onChange(): void {
        if (this.state.error !== this.props.error) {
            if (this.needToScroll()) {
                this.handleScroll();
            }
            this.setState({ error: this.props.error });
        }
    }

    needToScroll(): boolean {
        return !!this.props.error && !!this.props.scrollIntoView;
    }

    getRef(): React.RefObject<HTMLElement> {
        return this.ref;
    }

    handleScroll(): void {
        const node = this.getRef()?.current;
        if (node?.scrollIntoView) {
            requestAnimationFrame(() => {
                node.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "nearest",
                });
            });
        }
    }

    getContainer(children: React.ReactNode): React.ReactNode {
        const { onClick, invoice, disabled } = this.props;
        if (disabled) {
            return (
                <ButtonBase disabled style={{ display: "block", width: "100%" }}>
                    {children}
                </ButtonBase>
            );
        }

        return onClick ? (
            <ButtonBase disabled={invoice?.status === "validated" || !!disabled} onClick={onClick} style={{ display: "block", width: "100%" }}>
                {children}
            </ButtonBase>
        ) : (
            children
        );
    }

    render(): React.ReactNode {
        const { error, actions, id, severity, children, mt, mb, variant, icon } = this.props;
        const content = error || children;
        return (
            <>
                {content && (
                    <ThemeProvider>
                        <Box id={id} mt={mt} mb={mb}>
                            {this.getContainer(
                                <MUIAlert
                                    aria-atomic="true"
                                    role="alert"
                                    ref={this.getRef()}
                                    severity={severity || "error"}
                                    variant={variant}
                                    icon={icon}
                                >
                                    <Grid container alignItems="center" justifyContent="space-between">
                                        <Grid item xs>
                                            {content}
                                        </Grid>
                                        {actions && <Grid item>{actions}</Grid>}
                                    </Grid>
                                </MUIAlert>
                            )}
                        </Box>
                    </ThemeProvider>
                )}
            </>
        );
    }
}

export default Alert;

